
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonPage,
  IonRouterOutlet,
  IonLabel

} from "@ionic/vue";

import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import _t from '@/components/TranslateComponent.vue';
import { bool } from "aws-sdk/clients/signer";


const __default__ = defineComponent({
  name: 'App',
  components: {
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonPage,
    IonRouterOutlet,
    IonLabel,
  },
  mixins: [_t],

  created() {
    window.addEventListener('beforeinstallprompt', (e) => {
      this.$store.commit('updateDeferredPrompt', e);
    });
  },

  computed: {
    ...mapState({
      token: (state: any) => state.token,
      lang: (state: any) => state.lang,
      level: (state: any) => state.level,
      limited: (state: any) => state.limited
    }),
    menumargin() {
      if (this.token && !this.$route.path.includes('changepassword')) return "270px"; else return "0px";
    }
  }
},
);




import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "a8cf6f80": (_ctx.menumargin)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__