import { createStore } from 'vuex'
import router from '@/router'
import axios from 'axios';

export default createStore({
  state: {
    selectedProductIds: [],
    token: '',
    level: 'digital',
    country_id: null, 
    translations: {},
    lang: 'en_US',
    forceRefresh: false,
    deferredPrompt: null,
    limited : false
  },
  getters: {
    selectedProductIds: state => state.selectedProductIds,
    token: state => state.token,
    level: state => state.level,
    limited : state => state.limited,
    lang: state => state.lang,
    countryId: state => state.country_id,
    forceRefresh: state => state.forceRefresh,
    deferredPrompt: state => state.deferredPrompt,
    isLoggedIn: state => !!state.token,
    checkToken: state => {
      if (!state.token) {
        router.push({ path: 'home' });
      }
    }
  },
  mutations: {
    setTranslations(state, translations) {
      state.translations = translations;
    },
    updateCountryId: (state, country_id) => {
      state.country_id = country_id;
    },
    updateSelectedProductIds: (state, selectedProductIds) => {
      state.selectedProductIds = selectedProductIds
    },
    updateToken: (state, token) => {
      state.token = token;
      localStorage.setItem('sessionToken', token);
    },
    updateLevel: (state, level) => {
      state.level = level;
    },

    updateLimitedAccount: (state, mode) => {
      state.limited = mode;
    },

    updateLang: (state, lang) => {
      console.log("changeLang",lang);
      document.documentElement.lang = lang;
      state.lang = lang;
      localStorage.setItem('sessionLang', lang);
    },
    updateForceRefresh: (state, value) => {
      console.log("updateForceRefresh",value);
      state.forceRefresh = value;
    },
    updateDeferredPrompt: (state, value) => {
      state.deferredPrompt = value;
    }
  },
  actions: {

    loadTranslations({ commit }) {
      axios.get(process.env.VUE_APP_ROOT_API + '/action/translate')
        .then(response => {
          commit('setTranslations', response.data);
        })
        .catch(error => {
          console.log(error);
        });
    },
    updateCountryId({ commit }, country_id) {
    commit('updateCountryId', country_id);
  },
    updateSelectedProductIds({ commit }, selectedProductIds) {
      commit('updateSelectedProductIds', selectedProductIds)
    },
    updateToken({ commit }, token) {
      commit('updateToken', token)
    },
    updateLang({ commit }, lang) {
      commit('updateLang', lang) 
    },
    updateLimitedAccount({ commit }, mode) {
      commit('updateLimitedAccount', mode) 
    },
    updateLevel({ commit }, level) {
      commit('updateLevel', level) 
    },
    updateForceRefresh({ commit }, value) {
      commit('updateForceRefresh', value)
    },
    updateDeferredPrompt({ commit }, value) {
      commit('updateDeferredPrompt', value)
    }
  },
  modules: {
  }
})

