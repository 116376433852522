<script>

import axios from 'axios';
import { mapState } from 'vuex';

export default {
    data() {
        return {

        }
    },

    computed: {
        ...mapState({
            token: state => state.token,
            lang: state => state.lang,
            translations: state => state.translations
        })
    },
    methods: {

        loadTranslations() {
            axios.get(process.env.VUE_APP_ROOT_API + '/action/translate')
                .then(response => {
                    this.translations = response.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        _m(text, majuscule = false, variables = []) {
            const word = this._t(text, majuscule, variables);
            return word.charAt(0).toUpperCase() + word.substring(1);
        },
        _t(text, majuscule = false, variables = []) {
            if (this.translations && this.translations[this.lang]) {
                const list = this.translations[this.lang];
                const index = this.translations['index'];
                const lowercaseText = text.toLowerCase();
                const lowercaseIndex = Object.fromEntries(
                    Object.entries(index).map(([key, value]) => [key.toLowerCase(), value])
                );

                if (index[text]) {
                    const reference = index[text];
                    if (list[reference]) text = list[reference];
                }
                else if (lowercaseIndex[lowercaseText] && list[lowercaseIndex[lowercaseText]]) {
                    text = list[lowercaseIndex[lowercaseText]];
                } else this.addText(text);
            }
            if (majuscule) text = text.charAt(0).toUpperCase() + text.slice(1);
            if (variables) {
                // variables = ["{num}",22,"{price}",86"]
                if (variables[0] && variables[1]) text = text.replace(variables[0], variables[1]);
                if (variables[2] && variables[3]) text = text.replace(variables[2], variables[3]);
            }
            return text;
        },
        async addText(text) {
            console.log("add word", text);

                // Vérifier si le mot est déjà dans localStorage
                const storedWords = JSON.parse(localStorage.getItem('storedWords')) || [];
                if (storedWords.includes(text)) {
                    return;
                }

                // Ajouter le mot dans localStorage
                storedWords.push(text);
                localStorage.setItem('storedWords', JSON.stringify(storedWords));

            if (process.env.NODE_ENV === 'development') {
                console.log("Environnement de développement - requête non exécutée : ajout de " + text);
                return;
            }

            try {
                await axios.post(process.env.VUE_APP_ROOT_API + '/action/addtranslate', {
                    text: text
                }, {
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                });
            } catch (error) {
                console.error(error);
            }

        }
    }
}
</script>