// src/plugins/eventTracker.js
export default {
  install(Vue) {
    Vue.config.globalProperties.$trackEvent = (eventName, eventData) => {
      // Intégration avec Google Tag Manager ou tout autre système de suivi

      const transactionId =
        "txn_" +
        Date.now().toString() +
        Math.random().toString(36).substr(2, 9);

      window.dataLayer.push({
        event: eventName,
        transactionId: transactionId,
        ...eventData,
      });
    };
  },
};
