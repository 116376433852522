import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
// Added by the CLI
import './registerServiceWorker';
import { IonicVue } from '@ionic/vue';
import OneSignalVuePlugin from '@onesignal/onesignal-vue3'
//import VueGtag from "vue-gtag";
//import { createGtm } from '@gtm-support/vue-gtm';
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import store from './store';
import EventTracker from './plugins/eventTracker.js';



const app = createApp(App)
  .use(store)
  .use(EventTracker)
  .use(router)
  .use(IonicVue)

  .use(OneSignalVuePlugin, {
    appId: '5ef434e9-891b-4596-a5a5-e0cef9c22c95',
  })
  /*
  .use(
    createGtm({
      id: "GTM-5SR5NLVZ",
      vueRouter: router
    })
  )
 
.use(VueGtag, {
    config: { id: "GTM-5SR5NLVZ" }
  })
    */
;

router.isReady().then(() => {
  store.dispatch('loadTranslations');
  app.mount('#app');
});

