import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'
import store from '@/store';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: 'home'
  },
  {
    path: '/',
    component: TabsPage,
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/homePage.vue'),
        meta: { requiresAuth: true }
      },

      {
        path: 'messages',
        name: 'messages',
        component: () => import('@/views/chatPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'cart',
        name: 'cart',
        component: () => import('@/views/neworderPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'promocart/:id',
        name: 'promocart',
        component: () => import('@/views/neworderPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'addcart/:id',
        name: 'addcart',
        component: () => import('@/views/neworderPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'search/:search',
        name: 'search',
        component: () => import('@/views/neworderPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'search/:search/addcart/:id',
        name: 'searchandadd',
        component: () => import('@/views/neworderPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'orders',
        name: 'orders',
        component: () => import('@/views/ordersPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'company',
        name: 'company',
        component: () => import('@/views/companyPage.vue'),
        meta: { requiresAuth: true }
      },

      {
        path: 'institute',
        name: 'institute',
        component: () => import('@/views/institutePage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'digitalacademy',
        name: 'digitalacademy',
        component: () => import('@/views/digitalAcademyPage.vue'),
        meta: { requiresAuth: true }
      },

      {
        path: 'location/:id',
        name: 'location',
        component: () => import('@/views/locationPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'address',
        name: 'address',
        component: () => import('@/views/addressPage.vue'),
        meta: { requiresAuth: true }
      },

      {
        path: 'digitalcourse/:id',
        name: 'digitalcourse',
        component: () => import('@/views/digitalCoursePage.vue'),
        meta: { requiresAuth: true }
      },

      {
        path: 'digitalexam/:id',
        name: 'digitalexam',
        component: () => import('@/views/digitalExamPage.vue'),
        meta: { requiresAuth: true }
      },

      {
        path: 'digitalcourse/:id/:chapter',
        name: 'digitalchapter',
        component: () => import('@/views/digitalChapterPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'academy/:id',
        name: 'academy',
        component: () => import('@/views/academyPage.vue'),
        meta: { requiresAuth: true }
      },
      
      {
        path: 'assistance',
        name: 'assistance',
        component: () => import('@/views/assistancePage.vue')
      },


      
      {
        path: 'leadform',
        name: 'leadform',
        component: () => import('@/views/leadformPage.vue'),
        meta: { requiresAuth: true }
      },

      {
        path: 'leadformproduct/:id',
        name: 'leadformpoduct',
        component: () => import('@/views/leadformPage.vue'),
        meta: { requiresAuth: true }
      },

      {
        path: 'checkout',
        name: 'checkout',
        component: () => import('@/views/checkoutPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'calendar',
        name: 'calendar',
        component: () => import('@/views/calendarPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'addaction/:id',
        name: 'addaction',
        component: () => import('@/views/addPage.vue')
      },
      {
        path: 'marketing',
        name: 'marketing',
        component: () => import('@/views/marketingPage.vue')
      },
      {
        path: 'documents',
        name: 'documents',
        component: () => import('@/views/documentsPage.vue')
      },
      {
        path: 'mlist/:id',
        name: 'marketinglist',
        component: () => import('@/views/marketingPage.vue'),
      },
      {
        path: 'paid/:id',
        name: 'paid',
        component: () => import('@/views/paidPage.vue'),
        meta: { requiresAuth: true }
      },

      {
        path: 'invpaid/:id',
        name: 'invpaid',
        component: () => import('@/views/paidPage.vue'),
        meta: { requiresAuth: true }
      },

    
      {
        path: 'sav',
        name: 'sav',
        component: () => import('@/views/savPage.vue'),
        meta: { requiresAuth: true }
      },


      {
        path: 'ticket/:id',
        name: 'ticket',
        component: () => import('@/views/savPage.vue'),
        meta: { requiresAuth: true }
      },

      {
        path: 'repair/:id',
        name: 'repair',
        component: () => import('@/views/repairPage.vue'),
        meta: { requiresAuth: true }
      },


      {
        path: 'cancelledPayment',
        name: 'cancelledPayment',
        component: () => import('@/views/cancelledPaymentPage.vue')
      },
      {
        path: 'completedPayment',
        name: 'completedPayment',
        component: () => import('@/views/completedPaymentPage.vue')
      },
      {
        path: 'duplicatePayment',
        name: 'duplicatePayment',
        component: () => import('@/views/duplicatePaymentPage.vue')
      },
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/loginPage.vue')
      },
      {
        path: 'sign',
        name: 'sign',
        component: () => import('@/views/signPage.vue')
      },
      {
        path: 'changepassword',
        name: 'changepassword',
        component: () => import('@/views/passwordPage.vue')
      },
      {
        path: 'autologin',
        name: 'autologin',
        component: () => import('@/views/autologinPage.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {

  if (to.matched.some(route => route.meta.requiresAuth) && !store.getters.isLoggedIn) {
    
    if(to.path && to.path.includes("promocart")) {
      localStorage.setItem('redirectPath', to.path);
    }
    if(to.path && to.path.includes("addcart")) {
      localStorage.setItem('redirectPath', to.path);
    }
    next({ name: 'login', query: { redirect: to.path } });
  } if (store.getters.isLoggedIn && localStorage.getItem('redirectPath')) { 

    const redirectPath = localStorage.getItem('redirectPath');
    if (redirectPath) {
      console.log("redirectPath",redirectPath);
      localStorage.removeItem('redirectPath');
      next({ path: redirectPath });
    } else {
      next();
    }

    console.log("Loggé");
  } else {

    next();
  }
});


export default router
